import React from "react";
// import Link from "next/link";

import FindCar from "../../FindCar";

// import { links } from "../../../helper/constants";

import styles from "./styles.module.scss";
import { useIsBoiWebsite } from "../../Context/WebsiteType";

export default function VehicleSearchPanel() {

const isBoiWebsite = useIsBoiWebsite()

  return (
    <div className={isBoiWebsite ? styles.vehicleSearchPanelBoi : styles.vehicleSearchPanel}>
      <div className={`${isBoiWebsite ? styles.opacityBackBoi : styles.opacityBack} padding-top padding`}>
        <h1 className={`${isBoiWebsite ? styles.headerBoi : styles.header} huge-header white`}>
          Seamless transition to electric driving, {!isBoiWebsite ? <span className='blue'>for everyone</span> : "for everyone"}.
        </h1>
        
        <FindCar className={isBoiWebsite ? styles.findCarBoi : styles.findCar} />
      </div>

      {/* <div className={styles.evGuide}> */}
        {/* <h1 className={`${styles.evGuideHeader} big-header`}>
          We can guide you to find the right electric vehicle
        </h1>

        <Link href={links.ev_guide} passHref>
          <button className={`${styles.evGuideBtn} blue-button`}>
            Ev Guide For You
          </button>
        </Link> */}
      {/* </div> */}
    </div>   
  )
}