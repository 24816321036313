import React, { useMemo, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import useMobile from "../Hooks/Mobile";

import Select from "react-select";
import IconOption from "../IconOption";
import MakesModelsTool from "../MakesModelsTool";
import SearchTool from "../SearchTool";
import BodyType from "../BodyType";
import VehicleTypesSelector from "../VehicleTypesSelector";

import { links, vehicleTypes, bodyTypes } from "../../helper/constants";
import { event } from "../../lib/gtag";

import { renderMultiSelector } from "../../utils/renderer";
import { vehicleSearchEvent } from "../../utils/Analytics";

import IOption from "../../interfaces/IOption";

import styles from "./styles.module.scss";
import { vehicleSearchTheme, getVehicleSearchStyle, getVehicleSearchStyleBoi } from "../../helper/style";
import SearchIcon from "../../public/assets/icons/search.svg";
import { useIsBoiWebsite } from "../Context/WebsiteType";

interface FindCarProps {
	className?: string;
}

export default function FindCar({ className }: FindCarProps) {
	const [makes, setMakes] = useState<IOption[]>([]);
	const [models, setModels] = useState<IOption[]>([]);

	const screen550 = useMobile(550);
	const screen900 = useMobile(900);
	const screen1440 = useMobile(1440);

	const characterToShow = useMemo(() => {
		if (screen550) return 20;
		if (screen900) return 30;
		if (screen1440) return 22;
		return 18;
	}, [screen1440, screen550, screen900]);

	const { makeOptions, modelOptions } = MakesModelsTool(makes, setModels, vehicleTypes.private);

	const vehicleSearch = SearchTool(makes, models, links.private, "vehicleSearch", undefined, true);

	const onSearch = () => {
		event("homeSearchClick");

		vehicleSearchEvent(
			isBoiWebsite,
			makes.map((make) => make.value),
			models.map((model) => model.value)
		);

		vehicleSearch();
	};

	const isBoiWebsite = useIsBoiWebsite();

	return (
		<div className={`${isBoiWebsite ? styles.findCarBoi : styles.findCar} ${className}`}>
			<VehicleTypesSelector className={`${isBoiWebsite ? styles.vehicleTypesBoi : styles.vehicleTypes}`} />

			<div className={`${isBoiWebsite ? styles.findCarHolderBoi : styles.findCarHolder}`}>
				<div className={`${isBoiWebsite ? styles.carSelectorsHolderBoi : styles.carSelectorsHolder}`}>
					<div className={`${isBoiWebsite ? styles.selectHolderBoi : styles.selectHolder}`}>
						<Select
							instanceId="1"
							name="make"
							value={makes}
							options={makeOptions}
							onChange={(makes: any) => {
								let options: IOption[] = [];
								Object.assign(options, makes);

								if (options.some((option) => option.value === "all")) {
									options = [];
								}

								setMakes(options);
							}}
							onFocus={() => event("homeMakesClick")}
							components={{ Option: IconOption }}
							placeholder={!makes.length ? "All Makes" : renderMultiSelector(makes, characterToShow)}
							isMulti
							isSearchable={true}
							hideSelectedOptions={false}
							controlShouldRenderValue={false}
							isClearable={false}
							closeMenuOnSelect={false}
							theme={vehicleSearchTheme}
							styles={(isBoiWebsite ? getVehicleSearchStyleBoi : getVehicleSearchStyle)(screen1440)}
						/>
					</div>

					<div className={`${isBoiWebsite ? styles.selectHolderBoi : styles.selectHolder}`}>
						<Select
							instanceId="2"
							name="models"
							value={models}
							options={modelOptions}
							onChange={(models: any) => {
								let options: IOption[] = [];
								Object.assign(options, models);

								if (options.some((option) => option.value === "all")) {
									options = [];
								}

								setModels(options);
							}}
							onFocus={() => event("homeModelsClick")}
							components={{ Option: IconOption }}
							placeholder={!models.length ? "Model" : renderMultiSelector(models, characterToShow)}
							isMulti
							isDisabled={!makes.length}
							isSearchable={true}
							hideSelectedOptions={false}
							controlShouldRenderValue={false}
							isClearable={false}
							closeMenuOnSelect={false}
							theme={vehicleSearchTheme}
							styles={(isBoiWebsite ? getVehicleSearchStyleBoi : getVehicleSearchStyle)(screen1440)}
						/>
					</div>

					<div className={`${isBoiWebsite ? styles.searchButtonBoi : styles.searchButton} ${isBoiWebsite ? 'dark-blue-button-boi' : 'blue-button'}`} onClick={onSearch}>
						<span>Search</span>
						<Image src={SearchIcon} alt="Search icon" />
					</div>
				</div>
			</div>

			<div className={`${isBoiWebsite ? styles.popularSearchesBoi : styles.popularSearches}`}>
				<p className={`${isBoiWebsite ? styles.popularHeaderBoi : styles.popularHeader} nav-button`}>Popular searches:</p>

				<Link href={links.all_electric} passHref>
					<p className={`${isBoiWebsite ? styles.popularOptionBoi : styles.popularOption} nav-button`} onClick={() => event("homePopularAllElectricClick")}>
						All Electric
					</p>
				</Link>

				<Link href={links.plug_in} passHref>
					<p className={`${isBoiWebsite ? styles.popularOptionBoi : styles.popularOption} nav-button`} onClick={() => event("homePopularPlugInClick")}>
						Plug-In Hybrids
					</p>
				</Link>

				<Link href={links.suv} passHref>
					<p className={`${isBoiWebsite ? styles.popularOptionBoi : styles.popularOption} nav-button`} onClick={() => event("homePopularSUVClick")}>
						SUV
					</p>
				</Link>

				<Link href={links.below_40k} passHref>
					<p className={`${isBoiWebsite ? styles.popularOptionBoi : styles.popularOption} nav-button`} onClick={() => event("homePopularUnder40kClick")}>
						Under €40,000
					</p>
				</Link>
			</div>

			<div className={`${isBoiWebsite ? styles.bodyTypesSearchBoi : styles.bodyTypesSearch}`}>
				<h1 className={`${isBoiWebsite ? styles.bodyTypeHeaderBoi : styles.bodyTypeHeader} big-header`}>Or Select by Body type:</h1>

				<div className={`${isBoiWebsite ? styles.bodyTypesBoi : styles.bodyTypes}`}>
					{bodyTypes.map((bodyType, index) => (
						<BodyType key={index} name={bodyType.name} eventGTM={bodyType.event} link={bodyType.link} image={bodyType.image} className={`${isBoiWebsite ? styles.bodyTypeBoi : styles.bodyType}`} />
					))}
				</div>
			</div>
		</div>
	);
}
