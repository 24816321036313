import React, { Fragment } from "react";
import dynamic from "next/dynamic";

import Footer from "../components/Footer";
import Header from "../components/Header";
import VehicleSearchPanel from "../components/HomeComponents/VehicleSearchPanel";
import GoogleAd from "../components/GoogleAd";
import { googleAdIds } from "../helper/constants";

const PopularVehicles = dynamic(() => import("../components/HomeComponents/PopularVehicles"));
const HelpAdvice = dynamic(() => import("../components/HomeComponents/HelpAdvice"));
const Services = dynamic(() => import("../components/HomeComponents/Services"));
const Tools = dynamic(() => import("../components/HomeComponents/Tools"));
const EVStatistics = dynamic(() => import("../components/HomeComponents/EVStatistics"));

import styles from "../styles/Home.module.scss";
import { useIsBoiWebsite } from "../components/Context/WebsiteType";
import EVShowEventPopup from "../components/EvShowEventPopup";

const HomePage = () => {
	const isBoiWebsite = useIsBoiWebsite();

	return (
		<Fragment>
			<Header transparent />

			<div className={`${styles.homePage} page`}>
				<VehicleSearchPanel />

				{isBoiWebsite ? (
					<>
						<article className={`${styles.slide} ${styles.nextCar}`}>
							<PopularVehicles />
						</article>

						<article className={`${styles.slide} ${isBoiWebsite ? styles.evBenefitsBoi : styles.evBenefits} ${isBoiWebsite ? styles.helpAdviceSectionBoi : styles.helpAdviceSection}`}>
							<div className={styles.contentHolder}>
								<h1>Help & Advice</h1>

								<HelpAdvice />
							</div>
							{!isBoiWebsite && <GoogleAd adId={googleAdIds.horizontal_banner} className={styles.adBanner} homePage />}
						</article>
					</>
				) : (
					<>
						<article className={`${styles.slide} ${isBoiWebsite ? styles.evBenefitsBoi : styles.evBenefits} ${isBoiWebsite ? styles.helpAdviceSectionBoi : styles.helpAdviceSection}`}>
							<div className={styles.contentHolder}>
								<h1>Help & Advice</h1>

								<HelpAdvice />
							</div>
							{!isBoiWebsite && <GoogleAd adId={googleAdIds.horizontal_banner} className={styles.adBanner} homePage />}
						</article>
						<article className={`${styles.slide} ${styles.greyBackground} ${styles.nextCar}`}>
							<PopularVehicles />
						</article>
					</>
				)}

				{!isBoiWebsite && (
					<article className={`${styles.slide} ${styles.evBenefits} ${styles.servicesPanel}`}>
						<div className={styles.contentHolder}>
							<h1 className={styles.servicesHeader}>Services</h1>

							<Services />
						</div>
					</article>
				)}

				<article className={`${styles.slide} ${isBoiWebsite ? styles.evBenefitsBoi : styles.evBenefits} ${isBoiWebsite ? styles.toolsBoi : styles.tools}`}>
					<div className={styles.contentHolder}>
						<h1>Useful Tools</h1>

						<Tools />
					</div>
				</article>

				<article className={`${styles.slide} ${styles.greyBackground} ${styles.evStatistics}`}>
					<EVStatistics />
				</article>
			</div>

			{/* <EVShowEventPopup /> */}

			<Footer />
		</Fragment>
	);
};
export default HomePage;
