import React from "react";
import Link from "next/link";

import { vehicleSearchOptions, vehicleSearchOptionsBoi } from "../../helper/constants/vehicle_types";
import { event } from "../../lib/gtag";

import styles from "./styles.module.scss";
import { useIsBoiWebsite } from "../Context/WebsiteType";

interface VehicleTypesSelectorProps {
	className?: string;
}

export default function VehicleTypesSelector({ className }: VehicleTypesSelectorProps) {

	const isBoiWebsite = useIsBoiWebsite()
	const vehicleOptions = isBoiWebsite ? vehicleSearchOptionsBoi : vehicleSearchOptions

	return (
		<div className={`${isBoiWebsite ? styles.vehicleTypesBoi : styles.vehicleTypes} ${className}`}>
			{(isBoiWebsite ? (vehicleOptions
				.filter((option) => option.isBoi)) :
				vehicleOptions) // Ensures only options with isBoi: true are rendered
				.map((option, index) => (
					<Link key={index} href={option.link} passHref>
						<a className={`${isBoiWebsite ? styles.typeBtnBoi : styles.typeBtn} ${option.blue ? styles.blueButton : ""} nav-button`} onClick={() => event(option.event)}>
							{option.label}
						</a>
					</Link>
				))}
		</div>
	);
}
