/* eslint-disable @next/next/no-img-element */
import React from 'react'
import Link from 'next/link'
import { event } from '../../lib/gtag'

import styles from './styles.module.scss'
import { useIsBoiWebsite } from "../Context/WebsiteType"

interface BodyTypeProps {
  name: string,
  image: string,
  link: string,
  eventGTM: string,
  className?: string
}

export default function BodyType({ name, image, link, eventGTM, className }: BodyTypeProps) {

  const isBoiWebsite = useIsBoiWebsite()

  return (
    <Link href={link} passHref>
      <div className={`${isBoiWebsite ? styles.bodyTypeBoi : styles.bodyType} ${className}`} onClick={() => event(eventGTM)}>
        <p className={`${isBoiWebsite ? styles.nameBoi : styles.name} big-text`}>{ name }</p>

        <img className={isBoiWebsite ? styles.bodyTypeImgBoi : styles.bodyTypeImg} src={image} alt={name} />
      </div>
    </Link>
  )
}